import React from 'react'

function Contact() {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}
        >
           <h1>Contact Me</h1> 
        </div>
    );
};

export default Contact;