import React from "react";

const About = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}
        >
            <h1>Me</h1>
            <div><p>Hellooooooo </p></div>
        </div>
    );
};

export default About;