import React from 'react'

function SignIn() {
    return (
        <div>
            <h1>Sign In</h1>
        </div>
    )
}

export default SignIn;