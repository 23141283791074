import React from 'react'

function SignUp() {
    return (
        <div>
            <h1>Sign Up and get started</h1>
        </div>
    )
}

export default SignUp;